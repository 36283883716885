import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src3441252649/src/src/templates/faq/index.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Card details required`}</h2>
    <h3>{`What card details need to be provided to Fidel to enrol a card?`}</h3>
    <p>{`If you are using our Create Card API, these are the required fields which need to be passed on to enrol a card:`}</p>
    <ul>
      <li parentName="ul">{`3-digit Country code of the card issuing country`}</li>
      <li parentName="ul">{`Expiry Month and Year`}</li>
      <li parentName="ul">{`Card number (15 - 16-digit long)`}</li>
      <li parentName="ul">{`Acceptance of the Terms of Use or Cardholder consent`}</li>
    </ul>
    <p>{`Please see more details below:`}</p>
    <p><a parentName="p" {...{
        "href": "https://reference.fidel.uk/reference/create-card"
      }}>{`Create Card API Reference`}</a></p>
    <h2>{`Error messages on card enrolment`}</h2>
    <h3>{`What are the different error messages Fidel will send me, on an invalid card enrolment?`}</h3>
    <p>{`Please see all error messages here: `}<a parentName="p" {...{
        "href": "https://reference.fidel.uk/page/create-card-error-responses"
      }}>{`Create Card Error Messages`}</a></p>
    <h2>{`Card Eligibility`}</h2>
    <h3>{`Which cards does Fidel support?`}</h3>
    <p>{`Only Eligible Payment Cards may become Linked Cards. Please note that not all Visa, MasterCard and American Express cards are able to become Eligible Payment Cards. The Payment Cards not eligible to become Linked Cards are Visa, MasterCard, and American Express Corporate cards, Visa, MasterCard, and American Express Purchasing cards, non-reloadable prepaid cards, government-administered prepaid cards (including EBT cards), healthcare (including Health Savings Account (HSA) or Flexible Spending Account (FSA) or insurance prepaid cards), Visa Buxx, and Visabranded, MasterCard-branded, and American Express-branded cards whose transactions are not processed through the Visa payment system, MasterCard payment system, and/or American Express payment system, and any other type of card notified to you by Fidel API from time to time.`}</p>
    <p>{`Fidel API and the Card Networks may in their sole and absolute discretion decide whether a Payment Card is eligible to become a Linked Card.
Depending on the territory your registered debit card transaction must be processed as a 'credit' (i.e., authorized with signature and not a PIN) transaction to make sure the transaction can be monitored.`}</p>
    <h2>{`Apple Pay/ Google Pay cards/ virtual PANs`}</h2>
    <h3>{`Can Fidel track cards added to digital wallets?`}</h3>
    <p>{`The answer is yes and no. Fidel can still track cards added to digital wallets such as Apple Pay and Google Pay as long as the physical PAN (i.e. long card number on the front of the card) has been enrolled. A digital wallet will tokenize the PAN information before sending your data to the merchant as a security measure. However, it has been tested previously that, as long as the physical PAN has been enrolled, Fidel will receive the transaction information.`}</p>
    <h2>{`User can’t enrol card`}</h2>
    <h3>{`Why are some cardholders unable to enroll their cards?`}</h3>
    <p>{`If a cardholder is unable to complete the enrollment process, it may be due to one of the following reasons (that were identified until now, more might exist):`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Issuer Restriction: Some card issuers have restrictions in place that may prevent enrollment. This is often a security or policy measure decided by the issuer or by the network itself. Example: Well Fargo credit card - Business Essential Mastercard. Wells Fargo asked Mastercard to not allow them to enroll this card on CLS (Mastercard Loyalty System used by Fidel).`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Digital-PAN/ PAR/ Token: It could be that the user has enrolled the tokenised PAN/ PAR instead of the actual long-number of the PAN, as that changes with every transaction, Fidel can not enroll these to Select Transactions API, as these are not static.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Card might not be eligible for Loyalty/ Reward programs, please see section `}<a parentName="p" {...{
            "href": "https://fidelapi.com/faq/cards-select/#card-eligibility"
          }}>{`Card Eligibility`}</a></p>
      </li>
    </ul>
    <p>{`Please see more details on `}<a parentName="p" {...{
        "href": "http://fidelapi.com/faq/cards-select/#apple-pay/-google-pay-cards/-virtual-pans"
      }}>{`Apple/Google Pay cards`}</a></p>
    <h2>{`Card expired`}</h2>
    <h3>{`What happens when my user's card expires? Are they required to re-enrol?`}</h3>
    <p>{`Fidel primarily tracks on the PAN (16 digit card card number). There are two scenarios here:`}</p>
    <ul>
      <li parentName="ul">{`Issuer sends new card with the same card number as before and new expiry date`}</li>
    </ul>
    <p>{`In this scenario, the same card PAN will continue to exist, eliminating the need for the user to re-enter their details.`}</p>
    <p>{`However, we would be returning the expiry date to you, so you know if the card has expired and you can ask the cardholder to update the date.`}</p>
    <ul>
      <li parentName="ul">{`Issuer sends new card number with new expiry date`}</li>
    </ul>
    <p>{`In most cases, due to security, however the Issuer sends a new card number, in these cases the 'old card' will need to be deleted by you and the 'new card' to be re-enrolled, we recommend this process due to security reasons.
You can use our Delete card API `}<a parentName="p" {...{
        "href": "https://reference.fidel.uk/reference/delete-card"
      }}>{`Delete Card`}</a>{` to delete the 'expired card' and ask the cardholder to 'enroll' their new card to the program.`}</p>
    <p>{`In both cases, Fidel will receive transactions from the networks for both the old PAN (until it is deleted) and the new PAN (once enrolled), which we will continue to forward to you.`}</p>
    <h2>{`Identify user`}</h2>
    <h3>{`I wish to be able to identify my user when they enrol their card`}</h3>
    <p>{`Fidel does not collect or store personal information however there is a clear rationale for clients having access to this to fully maximise the card linking information. On each card enrolment, the client has the option to pass additional metadata which will be received and returned back to the client endpoint exactly as it has been submitted. In addition to this, each transaction that tracks on the enrolled card going forward, the metadata will be returned as part of the transaction payload.`}</p>
    <h2>{`SDK or API`}</h2>
    <h3>{`I see that I can send cardholder data through one of your SDKs or direct to API via the createCard endpoint. Which one is more suitable for me?`}</h3>
    <p>{`The answer lies largely in the clients use case and more specifically how they handle their user information. However, there are some key considerations to take into account.`}</p>
    <h3>{`createCard (API)`}</h3>
    <p>{`First and foremost, this endpoint ingests card data in its raw form i.e. full PAN, Expiry Date and Country of Issue. The usage of this endpoint dictates that the sender `}<strong parentName="p">{`must be fully PCI Compliant`}</strong>{`. Should the client indicate that they hold this certification, Fidel will require a copy of their `}<strong parentName="p">{`Attestation of Compliance`}</strong>{`, this is essentially documented evidence that the customer has completed the applicable questionnaires and scans and that their license is still valid (typically an attestation will last for a period of 12 months).`}</p>
    <p>{`Upsides:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`You are in full control of your card enrolment phase;`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`If you currently hold cards on file you can send this information directly to the endpoint.`}</p>
      </li>
    </ul>
    <p>{`Downsides:`}</p>
    <ul>
      <li parentName="ul">{`PCI Compliance is required.`}</li>
    </ul>
    <h3>{`SDK`}</h3>
    <p>{`This is the more widely used option with the main benefit being that the customer is not required to hold PCI Compliance certification. The SDK will inject a secure iFrame into the clients card linking application (should the customer choose to integrate it first) thus as a result transmit raw card information directly to Fidel without the client being exposed to it.`}</p>
    <p>{`Upsides:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Removes the requirement in becoming PCI Compliant;`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Fidel has customisation options whereby you can align the iFrame with the branding of your app.`}</p>
      </li>
    </ul>
    <p>{`Downsides:`}</p>
    <ul>
      <li parentName="ul">{`Enrolment essentially becomes event based, card enrolment can only happen one at a time at which point the card data must be presented by the end-user. Where this can become a blocker is in the case where a client holds card information and/or has a requirement to send the data to an additional receiver. See below section on `}<strong parentName="li">{`Vaulting`}</strong>{` as a possible solution.`}</li>
    </ul>
    <h2>{`Limit of no. of times a card can be enrolled`}</h2>
    <h3>{`Is there a limit on the number of times a card can be enrolled?`}</h3>
    <p>{`A single payment card can only be enrolled once within a single program. In addition, `}<strong parentName="p">{`Visa has a limitation of 5 active enrollments at the same time`}</strong>{`.
This means that a single Visa card (including virtual cards) can only be enrolled in up to 5 programs simultaneously across Fidel API.`}</p>
    <h2>{`Card lost/ stolen`}</h2>
    <h3>{`How does Fidel handle lost/ stolen Cards?`}</h3>
    <p>{`As a client, you need to inform clients if a cardholder has reported a lost/stolen card to you. You can simply delete the card and enroll the new card, once the cardholder has received it from their bank.`}</p>
    <h2>{`Cardholder Consent`}</h2>
    <h3>{`What is cardholder consent and how can I obtain this?`}</h3>
    <p>{`Fidel’s SDK contains a block of text included here is a checkbox that the cardholder must tick in order to proceed with the enrolment.`}</p>
    <h5>{`Cardholder consent`}</h5>
    <p><img parentName="p" {...{
        "src": "https://docs.fidel.uk/assets/images/cardholder-consent.png",
        "alt": "Cardholder consent",
        "title": "Cardholder consent"
      }}></img></p>
    <p><strong parentName="p">{`Note:`}</strong>{` If the client is enrolling cards directly through the `}<a parentName="p" {...{
        "href": "https://reference.fidel.uk/reference/create-card"
      }}>{`Create Card`}</a>{` endpoint, they `}<strong parentName="p">{`must`}</strong>{` ensure that the consent piece is managed by themselves and that this is obtained from the cardholder before any information is sent to Fidel API.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      